<template>
  <AppRow permission="WITHDRAW_ADMINISTRATION">
    <template v-slot>
      <v-col cols="12">
        <DatePicker
          label="Дата создания (выбрать промежуток)"
          @save="saveDates"
        />
      </v-col>

      <v-col>
        <ApolloQuery
          :query="require('../graphql/queries/getWithdrawsByCategory.gql')"
          :variables="{
            category,
            createdFrom,
            createdTo,
            pageIndex: (page - 1) * itemsPerPage,
            pageSize: itemsPerPage,
          }"
        >
          <ApolloSubscribeToMore
            :document="require('../graphql/subscriptions/onNewWithdrawRequest.gql')"
            :update-query="onWithdrawAdded"
          />

          <template v-slot="{ result: { error: withdrawsError, data: withdrawsData }, isLoading: withdrawsIsLoading }">
            <!-- Error -->
            <AppNotification
              v-if="withdrawsError"
              :message="withdrawsError.message"
            />

            <!-- Result -->
            <v-card
              v-else-if="withdrawsData || withdrawsIsLoading === 1"
              :loading="withdrawsIsLoading === 1"
              class="px-4 pb-3"
              flat
            >
              <template v-if="withdrawsData">
                <v-card-title>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      Выводы: {{ withdrawsData.getWithdrawsByCategory.total }}
                    </v-col>
                    <v-col class="text-right d-sm-block d-flex justify-center">
                      <v-btn-toggle
                        v-model="category"
                        mandatory
                      >
                        <v-btn
                          color="success"
                          value="GREEN"
                          @click="firstPage"
                        >
                          <span class="hidden-sm-and-down">Зелёная</span>
                        </v-btn>
                        <v-btn
                          color="purple"
                          value="VIOLET"
                          @click="firstPage"
                        >
                          <span class="hidden-sm-and-down">Фиолетовая</span>
                        </v-btn>
                        <v-btn
                          color="error"
                          value="RED"
                          @click="firstPage"
                        >
                          <span class="hidden-sm-and-down">Красная</span>
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-data-table
                  :headers="headers"
                  :items="withdrawsData.getWithdrawsByCategory.withdraws"
                  :items-per-page="itemsPerPage"
                  disable-sort
                  hide-default-footer
                  style="white-space: pre-line"
                >
                  <template v-slot:top>
                    <ApolloMutation
                      :mutation="require(
                        !accept
                          ? '../graphql/mutations/denyWithdraw.gql'
                          : walletType === 'manual_withdraw'
                            ? '../graphql/mutations/acceptManualWithdraw.gql'
                            : '../graphql/mutations/acceptWithdraw.gql'
                      )"
                      :refetch-queries="refetchQueriesAfterMyMutation"
                      :variables="walletType === 'manual_withdraw'
                        ? {
                          withdrawId: id,
                          status: 'PENDING',
                        }
                        : {
                          id,
                          reason,
                        }"
                    >
                      <template v-slot="{ mutate, loading, error: acceptOrDenyError }">
                        <v-dialog
                          v-model="dialog"
                          max-width="400px"
                          persistent
                        >
                          <v-card :loading="loading">
                            <v-card-title>{{ dialogTitle }}</v-card-title>
                            <v-card-text v-if="!accept">
                              <v-text-field
                                v-model="reason"
                                label="Причина"
                              />
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer />

                              <v-btn
                                color="primary"
                                text
                                @click="dialog = false"
                              >
                                Отмена
                              </v-btn>
                              <v-btn
                                color="error"
                                text
                                @click="mutate()"
                              >
                                {{ buttonText }}
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                        <AppNotification
                          v-if="acceptOrDenyError"
                          :icon-disabled="true"
                          :message="acceptOrDenyError.message"
                        />
                      </template>
                    </ApolloMutation>
                  </template>

                  <template v-slot:[`item.createdAt`]="{ item }">
                    {{ item.createdAt | moment('DD.MM.YYYY, HH:mm') }}
                  </template>

                  <template v-slot:[`item.amount`]="{ item }">
                    {{ item.amount }}
                  </template>

                  <template v-slot:[`item.accountNumber`]="{ item }">
                    {{ getAccountNumber(item.accountNumber) }}
                  </template>

                  <template v-slot:[`item.outIn`]="{ item }">
                    {{ item.outIn ? (100 * item.outIn).toFixed(0) : item.outIn }}%
                  </template>

                  <template v-slot:[`item.action`]="{ item }">
                    <v-btn
                      :to="{ name: 'users', params: { id: item.userId }}"
                      class="text-none"
                      depressed
                      height="36"
                      x-small
                      style="margin-top: .4rem"
                    >
                      Подробнее об игроке
                    </v-btn>
                    <v-btn
                      class="text-none ma-1"
                      depressed
                      height="36"
                      x-small
                      style="margin-top: .7rem !important"
                      @click.stop="denyWithdraw(item)"
                    >
                      Отклонить
                    </v-btn>
                    <v-btn
                      class="text-none ma-1"
                      color="primary"
                      depressed
                      height="36"
                      x-small
                      style="margin-top: .7rem !important"
                      @click.stop="acceptWithdraw(item)"
                    >
                      Одобрить
                    </v-btn>
                  </template>
                </v-data-table>
                <v-divider />

                <div class="text-center pt-3">
                  <v-pagination
                    v-model="page"
                    :length="withdrawsData.getWithdrawsByCategory.pages"
                    :total-visible="9"
                  />

                  <v-select
                    v-model="itemsPerPage"
                    :items="[5, 10, 15]"
                    class="pa-4 pt-5"
                    label="Строк на странице"
                    @change="firstPage"
                  />
                </div>
              </template>
            </v-card>

            <!-- No result -->
            <AppNotification
              v-else
              message="Нет результата :("
            />
          </template>
        </ApolloQuery>
      </v-col>
    </template>
  </AppRow>
</template>

<style scoped>

</style>

<script>
import AppRow from '@/components/AppRow.vue';
import DatePicker from '@/components/DatePicker.vue';
import AppNotification from '@/components/AppNotification.vue';
import getWithdrawsByCategory from '@/graphql/queries/getWithdrawsByCategory.gql';

export default {
  components: {
    AppRow,
    DatePicker,
    AppNotification,
  },
  data() {
    return {
      createdFrom: '',
      createdTo: '',
      category: 'GREEN',
      headers: [
        { text: 'id', value: 'id', class: 'white--text text-subtitle-2' },
        { text: 'userId', value: 'userId', class: 'white--text text-subtitle-2' },
        { text: 'username', value: 'username', class: 'white--text text-subtitle-2' },
        { text: 'Баланс', value: 'balance', class: 'white--text text-subtitle-2' },
        { text: 'Валюта', value: 'purseType', class: 'white--text text-subtitle-2' },
        { text: 'Роль', value: 'role', class: 'white--text text-subtitle-2' },
        { text: 'Дата заявки', value: 'createdAt', class: 'white--text text-subtitle-2' },
        { text: 'Вывод', value: 'amount', class: 'white--text text-subtitle-2' },
        { text: 'Метод вывода', value: 'walletType', class: 'white--text text-subtitle-2' },
        { text: 'Номер счета', value: 'accountNumber', class: 'white--text text-subtitle-2' },
        { text: 'SC', value: 'countIp', class: 'white--text text-subtitle-2' },
        { text: 'Кол-во депозитов', value: 'depositCount', class: 'white--text text-subtitle-2' },
        { text: 'Сумма депозитов', value: 'depositAmount', class: 'white--text text-subtitle-2' },
        { text: 'Кол-во выводов', value: 'withdrawCount', class: 'white--text text-subtitle-2' },
        { text: 'Сумма выводов', value: 'withdrawAmount', class: 'white--text text-subtitle-2' },
        { text: 'Out/In', value: 'outIn', class: 'white--text text-subtitle-2' },
        { text: '', value: 'action', align: 'center' },
      ],
      page: 1,
      itemsPerPage: 5,
      dialog: false,
      accept: false,
      id: '',
      reason: '',
      walletType: null,
    };
  },
  computed: {
    dateRangeText() {
      return this.dates.join(' ~ ');
    },
    dialogTitle() {
      return this.accept ? 'Одобрить заявку на вывод' : 'Отклонить заявку на вывод';
    },
    buttonText() {
      return this.accept ? 'Одобрить' : 'Отклонить';
    },
  },
  methods: {
    initPurseType(val) {
      switch (val) {
        case 0:
          return 'INR';
        case 1:
          return 'BTC';
        case 2:
          return 'USD';
        case 3:
          return 'EUR';
        case 4:
          return 'ETC';
        case 5:
          return 'UAH';
        case 6:
          return 'RUB';
        case 7:
          return 'LTC';
        case 8:
          return 'DEMO';
        case 9:
          return 'ETH';
        case 10:
          return 'BRL';
        case 11:
          return 'BCH';
        case 12:
          return 'BNB';
        case 13:
          return 'DASH';
        case 14:
          return 'DOGE';
        case 15:
          return 'TRX';
        case 16:
          return 'USDT';
        case 17:
          return 'XMR';
        case 18:
          return 'ZEC';
        case 19:
          return 'XRP';
        case 20:
          return 'KZT';
        case 21:
          return 'VND';
        case 22:
          return 'UZS';
        case 23:
          return 'IDR';
        case 24:
          return 'AZN';
        case 25:
          return 'KGS';
        case 26:
          return 'PKR';
        case 27:
          return 'BDT';
        case 28:
          return 'CLP';
        case 29:
          return 'PEN';
        case 30:
          return 'COP';
        case 31:
          return 'MXN';
        case 32:
          return 'PYG';
        case 33:
          return 'UYU';
        case 34:
          return 'BOB';
        case 35:
          return 'CRC';
        case 36:
          return 'GTQ';
        case 37:
          return 'PAB';
        case 38:
          return 'NIO';
        case 39:
          return 'ARS';
        case 40:
          return 'SVC';
        case 41:
          return 'NGN';
        default:
          return undefined;
      }
    },
    saveDates(dates) {
      [this.createdFrom, this.createdTo] = dates;
    },
    onWithdrawAdded(previousResult, { subscriptionData }) {
      const newResult = {
        getWithdrawsByCategory: [...previousResult.getWithdrawsByCategory],
      };
      newResult.getWithdrawsByCategory.push(subscriptionData.data.onNewWithdrawRequest);
      return newResult;
    },
    refetchQueriesAfterMyMutation() {
      this.dialog = false;

      return [{
        query: getWithdrawsByCategory,
        variables: {
          category: this.category,
          createdFrom: this.createdFrom,
          createdTo: this.createdTo,
          pageIndex: (this.page - 1) * this.itemsPerPage,
          pageSize: this.itemsPerPage,
        },
      }];
    },
    denyWithdraw(item) {
      this.dialog = true;
      this.accept = false;
      this.walletType = item.walletType;
      this.id = +item.id;
    },
    acceptWithdraw(item) {
      this.dialog = true;
      this.accept = true;
      this.walletType = item.walletType;
      this.id = +item.id;
      this.reason = '';
    },
    firstPage() {
      this.page = 1;
    },
    getAccountNumber(str) {
      try {
        const dict = JSON.parse(str);

        return [...Object.entries(dict)]
          .filter(it => it[0] !== 'store_values_checkbox')
          .map(it => it.join(': '))
          .join('\n');
      } catch (e) {
        console.error(e);
        return str;
      }
    },
  },
};
</script>
